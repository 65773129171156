export enum IconSizes {
  IC__16 = "IC__16",
  IC__20 = "IC__20",
  IC__24 = "IC__24",
  IC__30 = "IC__30",
  IC__35 = "IC__35",
  IC__60 = "IC__60",
  IC__imagePreview = "IC__image-preview",
  IC__filter = "IC__filter",
  IC__weddingShare = "IC__weddingShare",
  IC__packagesWhyHire = "IC__packagesWhyHire",
  IC__packagesFAQ = "IC__packagesFAQ",
  IC__locusBtn = "IC__locusBtn",
  IC__footer = "IC__footer",
  IC__homeAsterisk = "IC__homeAsterisk",

  //Old Icon Sizes
  IC__default = "IC__default",
  IC__googleIcon = "IC__googleIcon",
  IC__indexAsterisk = "IC__indexAsterisk",
  IC__longArrow = "IC__longArrow",
  IC__contactMe = "IC__contactMe",
  IC__packagesTouch = "IC__packagesTouch",
  IC__packagesFooter = "IC__packagesFooter",
  IC__packagesFooterArrow = "IC__packagesFooterArrow",
  IC__formSubmit = "IC__formSubmit",
  IC__menuOverlayIcons = "IC__menuOverlayIcons",
}
