import { BlogDetail } from "src/models/blog-detail.model";
import { BlogList } from "src/models/blog-list.model";
import { BlogCategory } from "src/enums/blog-category.enum";
import { Slugs } from "../enums/slugs.enum";

const getBlogCategory = (blog: BlogDetail | BlogList) => {
  const _tags = blog.tags || [];
  const categoryTag = _tags.find(t =>
    (t.slug || "").startsWith(`${Slugs.BLOG_CATEGORY_PREFIX}`)
  );

  return categoryTag && (categoryTag.slug.substr(9) as BlogCategory);
};

const removeBlogCategoryTags = (blog: BlogDetail | BlogList) => {
  return (blog.tags || []).filter(
    t =>
      !(
        (t.slug || "").startsWith(`${Slugs.BLOG_CATEGORY_PREFIX}-`) ||
        (t.slug || "").startsWith(`${Slugs.BLOG_SUBCATEGORY_PREFIX}-`)
      )
  );
};

const smallScreenQuery = "(max-width: 767px)";
const aboveLargeMobile = "(min-width: 900px)";
const aboveIpadPro = "(min-width: 1024px)";
const whoAmIMobile = "(min-width: 1100px)";
const aboveDesktop = "(min-width: 1200px)";

const objectToGetParams = (object: {
  [key: string]: string | number | undefined | null;
}) => {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join("&")}` : "";
};

const getPinterestShareUrl = (
  description: string,
  url: string,
  media: string
) => {
  return (
    "https://pinterest.com/pin/create/button/" +
    objectToGetParams({
      url,
      media,
      description,
    })
  );
};

const getFacebookShareUrl = (title: string, url: string) => {
  return (
    "https://www.facebook.com/sharer/sharer.php" +
    objectToGetParams({
      quote: title,
      u: url,
    })
  );
};

const getLinkedinShareUrl = (posterTitle: string, articleUrl: string) => {
  return (
    `https://www.linkedin.com/sharing/share-offsite` +
    objectToGetParams({
      url: articleUrl,
      title: posterTitle,
    })
  );
};

const getTwitterShareUrl = (
  articleTitle: string,
  articleUrl: string,
  imageUrl: string,
  hashtags: string[] = []
) => {
  return (
    "https://twitter.com/intent/tweet" +
    objectToGetParams({
      text: articleTitle,
      url: articleUrl,
      via: imageUrl,
      hashtags: hashtags.length > 0 ? hashtags.join(",") : undefined,
    })
  );
};

function filterPackage<
  T extends {
    location_slug: string;
  }
>(currentPackage: T, packages: T[]): T[] {
  const _exclusionMap: { [location: string]: string[] } = {
    hawaii: [Slugs.LOCATION_HAWAII],
    kauai: [Slugs.LOCATION_HAWAII],
    maui: [Slugs.LOCATION_HAWAII],
    "sf-bay-area": [Slugs.LOCATION_HAWAII],
  };

  const _excludedPackages = _exclusionMap[currentPackage.location_slug] || [];

  const otherPackages = packages.filter(
    p =>
      !_excludedPackages.includes(p.location_slug) &&
      p.location_slug !== currentPackage.location_slug
  );

  if (currentPackage.location_slug === Slugs.LOCATION_HAWAII) {
    return otherPackages;
  }

  return [currentPackage, ...otherPackages];
}

const isNullOrEmptyString = test => {
  return !test || !test.length;
};

export {
  getBlogCategory,
  removeBlogCategoryTags,
  getLinkedinShareUrl,
  getTwitterShareUrl,
  getFacebookShareUrl,
  getPinterestShareUrl,
  filterPackage,
  isNullOrEmptyString,
  smallScreenQuery,
  aboveLargeMobile,
  aboveIpadPro,
  aboveDesktop,
  whoAmIMobile
};
