export const TEXT_TRANSITION_DURATION = 4500;
export const LAZY_LOAD_OFFSET = 1500;
export const TESTIMONIALS_DURATION = 10000;

export const LOCATIONS = [
  "Kauai, Hawaii",
  "San Francisco Bay Area, California",
  "Maui, Hawaii",
];

export const HOME_ROTATING_WORDS = [
  "Good",
  "Arty",
  "Moody",
  "Edgy",
  "Timeless",
];

export const ENV_SITE_URL = process.env.SITE_URL;
export const ENV_GOOGLE_ANALYTICS_KEY = process.env.GATSBY_GOOGLE_ANALYTICS_KEY

export const PROD_BASE_URL = "https://www.afewgoodclicks.net";
