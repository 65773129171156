import React from "react";
import styles from "./SVGContainer.module.scss";
import cx from "classnames";
import { IconSizes } from "../../../enums/icon-sizes";

interface Props {
  children: React.ReactNode;
  iconSize?: IconSizes;
  onClick?: (e) => void;
  className?: string;
}

const SVGContainer: React.FC<Props> = (props: Props) => {
  const { iconSize = IconSizes.IC__20 } = props;
  let className;

  switch (iconSize) {
    case IconSizes.IC__homeAsterisk:
      className = styles.IC__homeAsterisk;
      break;
    case IconSizes.IC__16:
      className = styles.IC__16;
      break;
    case IconSizes.IC__20:
      className = styles.IC__20;
      break;
    case IconSizes.IC__24:
      className = styles.IC__24;
      break;
    case IconSizes.IC__30:
      className = styles.IC__30;
      break;
    case IconSizes.IC__35:
      className = styles.IC__35;
      break;
    case IconSizes.IC__60:
      className = styles.IC__60;
      break;
    case IconSizes.IC__imagePreview:
      className = styles.IC__imagePreview;
      break;
    case IconSizes.IC__filter:
      className = styles.IC__filter;
      break;
    case IconSizes.IC__weddingShare:
      className = styles.IC__weddingShare;
      break;
    case IconSizes.IC__footer:
      className = styles.IC__footer;
      break;
    case IconSizes.IC__googleIcon:
      className = styles.IC__googleIcon;
      break;
    case IconSizes.IC__longArrow:
      className = styles.IC__longArrow;
      break;
    case IconSizes.IC__packagesWhyHire:
      className = styles.IC__packagesWhyHire;
      break;
    case IconSizes.IC__packagesFAQ:
      className = styles.IC__packagesFAQ;
      break;
    case IconSizes.IC__packagesTouch:
      className = styles.IC__packagesTouch;
      break;
    case IconSizes.IC__packagesFooter:
      className = styles.IC__packagesFooter;
      break;
    case IconSizes.IC__packagesFooterArrow:
      className = styles.IC__packagesFooterArrow;
      break;
    case IconSizes.IC__default:
      className = styles.IC__default;
      break;
    case IconSizes.IC__contactMe:
      className = styles.IC__contactMe;
      break;
    case IconSizes.IC__locusBtn:
      className = styles.IC__locusBtn;
      break;
    case IconSizes.IC__indexAsterisk:
      className = styles.IC__indexAsterisk;
      break;
    case IconSizes.IC__menuOverlayIcons:
      className = styles.IC__menuOverlayIcons;
      break;
  }

  return (
    <span
      className={cx(styles.svgContainer, className, props.className ?? "")}
      onClick={e => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      {props.children}
    </span>
  );
};

export default SVGContainer;
