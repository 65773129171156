export enum Slugs {
  //Blog Category slugs
  BLOG_CATEGORY_PREFIX = "category",
  BLOG_SUBCATEGORY_PREFIX = "subcategory",
  BLOG_PREFIX = "blog",

  //Blog Tag slugs
  TAG_PREFIX = "tags",

  //Portfolio Group slugs
  PORTFOLIO_GROUP_PREFIX = "types",
  PORTFOLIO_GROUP_WEDDING = "wedding",
  PORTFOLIO_GROUP_MATERNITY = "maternity",
  PORTFOLIO_GROUP_PORTRAIT = "portraits",
  PORTFOLIO_GROUP_LIFESTYLE = "lifestyle",
  PORTFOLIO_GROUP_FOOD = "food",

  //Portfolio Slugs
  PORTFOLIOS = "portfolios",
  PORTFOLIO_WEDDING = "wedding-photography",
  PORTFOLIO_ENGAGEMENT = "engagement",
  PORTFOLIO_NEWBORN = "newborn",
  PORTFOLIO_MATERNITY = "maternity",
  PORTFOLIO_PORTRAIT = "portraits",
  PORTFOLIO_FAMILY = "families",
  PORTFOLIO_FOOD = "food-photography",
  PORTFOLIO_EVENTS = "events",
  PORTFOLIO_TRAVEL = "travel",
  PORTFOLIO_PACKAGES_SUFFIX = "pricing",

  //Package Location slugs
  LOCATION_HAWAII = "hawaii",
  LOCATION_KAUAI = "kauai",
  LOCATION_MAUI = "maui",
  LOCATION_SF_BAY_AREA = "sf-bay-area",
}
