import React from "react"
import { Helmet } from "react-helmet/es/Helmet"

const TermlyScript = () => {
  const websiteUUID = "18c58a0d-210b-48d8-ad9c-f4413821354d";
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://app.termly.io/embed.min.js"
          data-auto-block="on"
          data-website-uuid={websiteUUID}
        />
      </Helmet>
    </>
  )
}

export default TermlyScript
