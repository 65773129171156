import React from "react";
import styles from "./Overlay.module.scss";
import cx from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const Overlay: React.FC<Props> = (props: Props) => {
  return (
    <div className={cx(styles.Overlay, props.className ?? "")} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Overlay;
