import React, { useEffect, RefObject, useContext } from "react";
import styles from "./AppWrapper.module.scss";
import GtagScript from "../gtag-script/GtagScript";
import { DoNotTrackContext } from "../../../contexts/do-not-track.context";
import TermlyScript from "../../../components/commons/termly-script/TermlyScript"

interface Props {
  children: React.ReactNode;
  handleScroll?: Function;
  reference?: RefObject<HTMLDivElement>;
}

const AppWrapper: React.FC<Props> = (props: Props) => {
  const vhFunc = () => {
    document
      .querySelector(":root")!
      .style.setProperty("--vh", window.innerHeight / 100 + "px");
  };

  useEffect(() => {
    window.addEventListener("resize", vhFunc);

    return () => {
      window.removeEventListener("resize", vhFunc);
    };
  }, []);

  useEffect(() => {
    vhFunc();
  }, []);

  const { doNotTrack, setDoNotTrack } = useContext(DoNotTrackContext);

  useEffect(() => {
    // Setting Do Not Track Context
    const dnt = navigator.doNotTrack || window.doNotTrack;
    setDoNotTrack(dnt === "1" || dnt === "yes");
  }, [setDoNotTrack]);

  return (
    <div
      className={styles.App}
      ref={props.reference}
      onScroll={e => {
        if (props.handleScroll) {
          props.handleScroll(e);
        }
      }}
    >
      <GtagScript />
      <TermlyScript />
      {props.children}
    </div>
  );
};

export default AppWrapper;
